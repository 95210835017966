module.exports = [{
  plugin: require('/tmp/5d5730d5/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-96010824-1",
    "head": false
  }
}, {
  plugin: require('/tmp/5d5730d5/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];